import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default ({ isDefault, lastFour, profile, brand, expMonth, expYear }) => {
  return (
    <div className={`cc-card ${isDefault ? 'border-primary' : ''} card-width`}>
      <Row className='pb-0 pt-5 pr-5 pl-5'>
        <Col>
          <p className='number'>**** **** **** {lastFour}</p>
        </Col>
      </Row>
      <Row className='pb-3'>
        <Col className='info'>
          {isDefault ? (
            <p className='default-label badge badge-primary'>Default Card</p>
          ) : null}
          <p>{profile.name}</p>
        </Col>
        <Col>
          <p>{brand}</p>
          <p>
            {expMonth}/{expYear}
          </p>
        </Col>
      </Row>
    </div>
  );
};
