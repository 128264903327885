import React from 'react';
import { Router } from '@reach/router';

import EditProfile from '../clientPages/profile/edit';

export default () => {
  return (
    <Router>
      <EditProfile path='/profile/edit' />
    </Router>
  );
};
