import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import {
  createSubUser,
  getUserPermissions,
  sendEmailPasswordReset,
} from '../../utils/api';
import { toast } from 'react-toastify';

const InviteUser = ({
  handleClose,
  profile,
  auth0Token,
  token,
  inviteModal,
}) => {
  const [itemsChecked, setItemsChecked] = useState([]);
  const [loading, setLoading] = useState(false);
  const [secondLoader, setSecondLoader] = useState(false);
  const [userAuthId, setUserAuthId] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const [userEmail, setUserEmail] = useState(null);

  const fetchUserPermissions = async () => {
    const data = await getUserPermissions(11);
    setPermissions(data.settings);
  };

  useEffect(() => {
    if (inviteModal) {
      fetchUserPermissions();
    }
  }, [inviteModal]);

  function findItem(name, itemsToCheck) {
    return itemsToCheck.some((item) => item.name === name);
  }

  let subscr;
  if (profile.notes) {
    if (profile.notes.includes('Stripe Subscription')) {
      const firstSplit = profile.notes.split(',');
      const subSplit = firstSplit[1] ? firstSplit[1].split(':') : [];
      subscr = subSplit[1];
    }
  }

  const perms = itemsChecked.map((item) => {
    return item.name;
  });

  const suchFetch = async (params) => {
    var url = new URL(`https://gildform.auth0.com/api/v2/users`);
    if (params != null)
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    const dd = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${auth0Token}`,
      },
    });

    const tani = await dd.json();
    setUserEmail(tani);
    return tani;
  };

  const getUserByEmail = async (values) => {
    let params = { q: `email:${values.email}` };
    await suchFetch(params);
  };

  const addUserToDb = async (values) => {
    setLoading(true);
    const data = {
      email: values.email,
      user_metadata: {},
      blocked: false,
      email_verified: false,
      app_metadata: {},
      given_name: values.name,
      name: values.name,
      nickname: values.name,
      connection: 'Username-Password-Authentication',
      password: 'Company12345678!',
    };

    try {
      const userRes = await fetch(`https://gildform.auth0.com/api/v2/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${auth0Token}`,
        },
        body: JSON.stringify(data),
      });

      const dataUsr = await userRes.json();

      if (userRes.status === 201) {
        setLoading(false);
        setUserAuthId(dataUsr.user_id);
        const userData = {
          // sub: 'auth0|62a25535a37829b232a49801' testing
          sub: dataUsr.user_id,
          admin_id: profile.id,
          erp_id: profile.erp_id,
          userStripe: profile.stripe_id,
          subscription_id: subscr ? subscr.trim() : '',
          name: values.name,
          email: values.email,
          company: values.company,
        };
        const createUserRes = await createSubUser(userData, token);
      } else if (dataUsr.statusCode === 409) {
        setAuthError('This email already exists!');
        getUserByEmail(values);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log('error auth00000', error);
      setLoading(false);
      if (String(error).includes('409')) {
        setAuthError('This email already exists!');
        getUserByEmail(values);
      } else {
        setAuthError('There was something wrong with the account creation!');
      }
    }
  };

  const handleInvite = async (values) => {
    setSecondLoader(true);
    const dataEmail = {
      // result_url: 'http://localhost:9100/',
      result_url: `${'https://gildform.com/'}`,
      user_id: userAuthId,
      ttl_sec: 0,
      mark_email_as_verified: true,
      includeEmailInRedirect: true,
    };

    try {
      const ticketRes = await fetch(
        `https://gildform.auth0.com/api/v2/tickets/password-change`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${auth0Token}`,
          },
          body: JSON.stringify(dataEmail),
        }
      );
      const ticketData = await ticketRes.json();
      if (ticketRes.status === 201) {
        const data = {
          name: values.name,
          email: values.email,
          link: ticketData.ticket,
        };
        const emailRes = await sendEmailPasswordReset(data);
        const userData = {
          // sub: 'auth0|62a25535a37829b232a49801', testing
          sub: userAuthId,
          admin_id: profile.id,
          erp_id: profile.erp_id,
          userStripe: profile.stripe_id,
          subscription_id: subscr ? subscr.trim() : '',
          update: true,
          permissions: perms,
          name: values.name,
          email: values.email,
          company: values.company,
        };
        const createUserRes = await createSubUser(userData, token);
        setSecondLoader(false);
        toast.success('Invitation to the user was successful!', {
          className: 'bg-primary text-white',
          onClose: () => {
            handleClose();
          },
        });
      } else {
        setSecondLoader(false);
        toast.error('An error occurred inviting user!');
      }
    } catch (error) {
      console.log('error', error);
      if (String(error).includes('401')) {
      } else {
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={{ name: '', email: '', company: '' }}
        onSubmit={(values) => {
          handleInvite(values);
        }}
        render={({ errors, touched, ...props }) => (
          <Form onSubmit={props.handleSubmit} validated={props.isValid}>
            <div
              className='mb-1 text-center'
              style={{ fontSize: '19px', fontWeight: '500' }}
            >
              User Information
            </div>
            <div className='d-flex flex-row justify-content-between'>
              <div
                className='d-flex flex-column mb-1'
                style={{ width: '70%', marginRight: '8px' }}
              >
                <div>User Name</div>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Control
                    type='text'
                    placeholder=''
                    value={props.values.name}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    name='name'
                    isInvalid={!!errors.name}
                    isValid={!!!errors.name && touched.name}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div
                className='mb-1 d-flex flex-column'
                style={{ width: '70%', marginLeft: '8px' }}
              >
                <div>Email</div>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Control
                    type='email'
                    placeholder=''
                    value={props.values.email}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    name='email'
                    isInvalid={!!errors.email}
                    isValid={!!!errors.email && touched.email}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <div className='mb-1 d-flex flex-column' style={{ width: '70%' }}>
              <div>Company</div>
              <Form.Group controlId='formBasicEmail'>
                <Form.Control
                  type='text'
                  placeholder=''
                  value={props.values.company}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  name='company'
                  isInvalid={!!errors.company}
                  isValid={!!!errors.company && touched.company}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.company}
                </Form.Control.Feedback>
              </Form.Group>
              <div className='d-flex flex-row flex-end mb-3'>
                <div
                  className='step-1 mt-2 mr-2'
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  STEP 1:
                </div>
                <Button
                  onClick={() => {
                    addUserToDb(props.values);
                  }}
                  variant={
                    !props.values.email ||
                    !props.values.name ||
                    !props.values.company
                      ? 'outline-primary'
                      : 'primary'
                  }
                  disabled={
                    !props.values.email ||
                    !props.values.name ||
                    !props.values.company ||
                    userAuthId
                  }
                >
                  {loading ? (
                    <i className='fas fa-circle-notch fa-spin mr-1' />
                  ) : (
                    'Add user to the account'
                  )}
                </Button>
                {userAuthId && (
                  <div>
                    <i
                      className='fas fa-check'
                      style={{
                        marginLeft: '20px',
                        color: 'green',
                        fontSize: '25px',
                        marginTop: '6px',
                      }}
                    />
                  </div>
                )}
              </div>
              {authError && (
                <div className='d-flex text-danger'>
                  <span>{authError}</span>
                </div>
              )}
            </div>
            <div className='status-checklist'>
              <div className='perm-title'>
                Choose permissions for this user:
              </div>
              {permissions &&
                permissions.map((item, idx) => {
                  return (
                    <div className='checklist-item' key={idx}>
                      <div className='check-box'>
                        <input
                          key={idx}
                          type='checkbox'
                          name={`qst-${item.name}`}
                          className='qst-unit'
                          value={props.values[`qst-${item.name}`]}
                          onChange={(e) => {
                            const newItem = {
                              checked: e.target.checked,
                              name: item.name,
                            };
                            if (itemsChecked.length !== 0) {
                              if (!findItem(item.name, itemsChecked)) {
                                setItemsChecked((prevState) => [
                                  ...prevState,
                                  newItem,
                                ]);
                              } else {
                                const newItems = itemsChecked.map((item) => {
                                  if (item.name === newItem.name) {
                                    return {
                                      ...item,
                                      checked: e.target.checked,
                                    };
                                  } else {
                                    return item;
                                  }
                                });
                                setItemsChecked(newItems);
                              }
                            } else {
                              setItemsChecked((prevState) => [
                                ...prevState,
                                newItem,
                              ]);
                            }
                          }}
                          disabled={!userAuthId}
                        />
                      </div>
                      <div className='check-question'>{item.name}</div>
                    </div>
                  );
                })}
            </div>
            <div className='invite-foot'>
              <div
                className='step-1 mt-2 mr-2'
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                STEP 2:
              </div>
              <Button
                variant={'primary'}
                disabled={itemsChecked.length === 0}
                type={'submit'}
                className='btnInvite'
              >
                {secondLoader ? (
                  <i className='fas fa-circle-notch fa-spin mr-1' />
                ) : (
                  'Invite User'
                )}
              </Button>
              <Button variant='danger' onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default InviteUser;
