import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { createSubUser, getUserPermissions } from '../../utils/api';
import { toast } from 'react-toastify';

const EditUserAuth0 = ({
  handleClose,
  profile,
  token,
  inviteModal,
  user,
  company,
}) => {
  const [secondLoader, setSecondLoader] = useState(false);
  const [allPerms, setAllPermissions] = useState([]);

  function findItemSame(name, itemsToCheck) {
    return itemsToCheck && itemsToCheck.some((item) => item === name);
  }

  const fetchUserPermissions = async () => {
    const data = await getUserPermissions(11);
    const allPermissions = data.settings.map((it) => {
      const found = findItemSame(it.name, user.permissions);
      if (found) {
        return { ...it, checked: true };
      } else {
        return it;
      }
    });

    setAllPermissions(allPermissions);
  };

  useEffect(() => {
    if (inviteModal) {
      fetchUserPermissions();
    }
  }, [inviteModal, user]);

  let subscr;
  if (profile.notes) {
    if (profile.notes.includes('Stripe Subscription')) {
      const firstSplit = profile.notes.split(',');
      const subSplit = firstSplit[1] ? firstSplit[1].split(':') : [];
      subscr = subSplit[1];
    }
  }

  const perms = allPerms
    .filter((item) => item.checked)
    .map((item) => {
      return item.name;
    });

  const updateUser = async () => {
    setSecondLoader(true);

    try {
      const userData = {
        sub: user.id,
        admin_id: profile.id,
        erp_id: profile.erp_id,
        userStripe: profile.stripe_id,
        subscription_id: subscr ? subscr.trim() : '',
        update: true,
        permissions: perms,
        name: user.name,
        email: user.email,
        company: company,
        editErp: true,
      };
      const createUserRes = await createSubUser(userData, token);
      setSecondLoader(false);
      if (createUserRes.id) {
        toast.success('User udpate  was successful!', {
          className: 'bg-primary text-white',
          onClose: () => {
            handleClose();
          },
        });
      } else {
        setSecondLoader(false);
        toast.error('An error occurred editing user!');
      }
    } catch (error) { }
  };

  return (
    <div>
      <Formik
        initialValues={{ name: '', email: '' }}
        onSubmit={(values) => {
          updateUser();
        }}
        render={({ errors, touched, ...props }) => (
          <Form onSubmit={props.handleSubmit} validated={props.isValid}>
            <div
              className='mb-1 text-center'
              style={{ fontSize: '19px', fontWeight: '500' }}
            >
              User Information
            </div>
            <div className='d-flex flex-row justify-content-between'>
              <div
                className='d-flex flex-column mb-1'
                style={{ width: '70%', marginRight: '8px' }}
              >
                <div>User Name</div>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Control
                    type='text'
                    placeholder=''
                    value={user.name}
                    disabled={true}
                    name='name'
                  />
                </Form.Group>
              </div>
              <div
                className='mb-1 d-flex flex-column'
                style={{ width: '70%', marginLeft: '8px' }}
              >
                <div>Email</div>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Control
                    type='email'
                    placeholder=''
                    value={user.email}
                    disabled={true}
                    name='email'
                  />
                </Form.Group>
              </div>
            </div>
            <div className='status-checklist'>
              <div className='perm-title'>
                Choose permissions to edit for this user:
              </div>
              {allPerms.map((item, idx) => {
                return (
                  <div className='checklist-item' key={idx}>
                    <div className='check-box'>
                      <input
                        key={idx}
                        type='checkbox'
                        name={`qst-${item.name}`}
                        className='qst-unit'
                        value={props.values[`qst-${item.name}`]}
                        checked={item.checked}
                        onChange={(e) => {
                          const newItem = {
                            ...item,
                            checked: e.target.checked,
                          };

                          const newAll = allPerms.map((perm) => {
                            if (perm.name === newItem.name) {
                              return newItem;
                            } else {
                              return perm;
                            }
                          });
                          setAllPermissions(newAll);
                        }}
                      />
                    </div>
                    <div className='check-question'>{item.name}</div>
                  </div>
                );
              })}
            </div>
            <div className='invite-foot'>
              <Button variant={'primary'} type={'submit'} className='btnInvite'>
                {secondLoader ? (
                  <i className='fas fa-circle-notch fa-spin mr-1' />
                ) : (
                  'Edit Permissions'
                )}
              </Button>
              <Button variant='danger' onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default EditUserAuth0;
