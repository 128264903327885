import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Table,
  Tabs,
  Tab,
} from 'react-bootstrap';

import Layout from '../../components/layout';
import PrivateRoute from '../../components/auth/protected';
import MembershipSelect from '../../components/MembershipPopup';
import ProfileTable from '../../components/ProfileTable';
import CreditCardInfo from '../../components/creditcard/CardInfo';
import CreditCardModal from '../../components/creditcard/CreditCardModal';
import Modal from '../../components/Modal';
import SEO from '../../components/Seo';
import {
  getAuth0Token,
  getConnectAccount,
  getConnectAccountLink,
  getErpMembershipContent,
  getMembershipPlans,
  getTransactionsByCustomerId,
  openConnectAccount,
} from '../../utils/api';
import { toast } from 'react-toastify';

import { finishEdit } from '../../state/login/action';
import {
  updateUser,
  changeMembership,
  updateBilling,
} from '../../state/login/thunks';
import {
  setCustomerRoadmap,
  setUserChecklist,
} from '../../state/create/answer/action';
import InviteUser from '../../components/auth/InviteUser';
import { getClassName, getPermission, localTimeUS } from '../../utils/helpers';
import CustomTooltip from '../../components/Tooltip';
import EditUserAuth0 from '../../components/auth/EditUserAuth0';

const GATSBY_APP_URL = process.env.GATSBY_APP_URL;
const client_id = process.env.GATSBY_APP_STRIPE_CLIENT_ID;

const ProfilePage = ({
  updateBilling,
  profile,
  update,
  changeMembership,
  login,
  finishEdit,
  token,
  userErpId,
}) => {
  const [stripe, setStripe] = useState({});
  const [isMemberModalOpen, openMemberModal] = useState(false);
  const [isCardModalOpen, openCardModal] = useState(false);
  const [cardErrors, setCardErrors] = useState(undefined);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [inviteModal, openInviteModal] = useState(false);
  const [showTool, setShowTool] = useState(false);
  const [showMemTool, setShowMemTool] = useState(false);
  const [activeTab, setActiveTab] = useState('edit-profile');
  const [selectedPlan, _setSelectedPlan] = useState({});

  const [erpMembershipContent, setErpMembershipContent] = useState([]);
  const [cardInfoLoad, setCardInfoLoad] = useState(false);
  const [chosenMembership, setChosen] = useState(null);
  const [auth0Token, setAuthToken] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [transactions, setTransactions] = useState([]);
  const [stripeConnectLoginLink, setStripeConnectLoginLink] = useState(null);

  const [stripeComplete, setStripeComplete] = useState(false);

  const stripeLink = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${`${GATSBY_APP_URL}/profile`}&client_id=${client_id}&state=${`Gildform123@`}`;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchErpMembershipContent = async () => {
    const res = await getErpMembershipContent();
    const tt = res ? res.filter((it) => it.active) : [];

    setErpMembershipContent(tt);
  };

  useEffect(() => {
    fetchErpMembershipContent();
  }, []);

  const fetchTransactionsByCustomerId = async (id) => {
    const res = await getTransactionsByCustomerId(id);
    if (res) {
      setTransactions(res);
    }
  };

  const fetchStripeConnectLoginLink = async (id) => {
    const res = await getConnectAccountLink(id);
    if (res.status === 200) {
      const data = await res.json();
      setStripeConnectLoginLink(data.url);
    }
  };

  const getStripeConnectAccount = async (id) => {
    const res = await getConnectAccount(id);
    if (res.status === 200) {
      const data = await res.json();
      if (
        (data.charges_enabled &&
          data.capabilities.transfers === 'active' &&
          data.payouts_enabled) ||
        data.details_submitted
      ) {
        setStripeComplete(true);
      }
    }
  };

  const openStripeAcc = async (id) => {
    const res = await openConnectAccount(id);
    if (res) {
      setStripeConnectLoginLink(res.url);
      update({ ...profile, stripeConnectId: res.account_id });
      getConnectAccount(res.account_id);
    }
  };

  useEffect(() => {
    if (activeTab === 'transactions') {
      fetchTransactionsByCustomerId(profile.erp_id);
      if (profile.stripeConnectId) {
        getStripeConnectAccount(profile.stripeConnectId);
      } else {
        openStripeAcc(null);
      }
    }
  }, [activeTab, profile]);

  useEffect(() => {
    if (stripeComplete) {
      fetchStripeConnectLoginLink(profile.stripeConnectId);
    }
  }, [stripeComplete]);

  const collectCardInfo = async () => {
    setCardInfoLoad(true);
    setCardErrors(undefined);
    const stripeResponse = await stripe.stripe.createToken(stripe.card);
    if (stripeResponse.error) {
      setCardErrors(stripeResponse.error);
      return;
    }
    const tok = stripeResponse.token.id;
    const updatedBilling = await updateBilling(
      profile.subUser ? profile.admin_id : profile.id,
      tok
    );
    if (updatedBilling.cardInfo) {
      if(updatedBilling.cardInfo.error){
        toast.error(`An error occurred during this operation: ${updatedBilling.cardInfo.error.message}`);
      }
    }
    setCardInfoLoad(false);
    openCardModal(false);
  };

  const fetchMemberships = async () => {
    setLoading(true);
    const memberships = await getMembershipPlans();
    setPlans(memberships ? memberships.data : []);
    if (profile.membership) {
      setChosen(
        memberships.data.find(
          (plan) => plan.metadata.nickname == profile.membership.name
        )
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (plans.length === 0) {
      fetchMemberships();
    }
  }, [profile]);

  const getToken = async () => {
    const res = await getAuth0Token(token);
    if (res.access_token) {
      setAuthToken(res.access_token);
    }
  };

  useEffect(() => {
    if (inviteModal && !auth0Token) {
      getToken();
    }
  }, [inviteModal]);

  useEffect(() => {
    if (login.userLoading) {
      finishEdit();
    }
  }, []);

  const permissions = profile && profile.subUser ? profile.permissions : [];

  const target = useRef(null);
  const membershipTarget = useRef(null);

  useEffect(() => {
    if (!userErpId) {
      navigate('/create');
    }
  }, [userErpId]);

  return (
    <>
      <PrivateRoute>
        <SEO title='Edit your Profile' />
        <Layout className='justify-content-end profile'>
          <Row
            className='page-title edit-page-title'
            style={{
              justifyContent: 'space-between',
              padding: '39px 16px',
              marginTop: '1.4rem',
            }}
          >
            <Col>
              <h2>
                {profile.subUser
                  ? profile.subUserName
                  : profile.firstName + ' ' + profile.lastName}
              </h2>
              {profile.subUser ? (
                <div
                  style={{
                    marginLeft: '2px',
                    fontSize: '18px',
                    fontStyle: 'italic',
                  }}
                >
                  Company: {profile.fax}
                </div>
              ) : null}
            </Col>
            <Col className='mt-2'>
              <span
                className='mr-1'
                style={{ fontWeight: 'bold' }}
                ref={membershipTarget}
                onMouseEnter={() => {
                  if (profile.subUser) {
                    if (!getPermission(permissions, 'membership')) {
                      setShowMemTool(true);
                    }
                  }
                }}
                onMouseLeave={() => {
                  if (profile.subUser) {
                    setShowMemTool(false);
                  }
                }}
              >
                Current Membership:
              </span>
              <CustomTooltip
                show={showMemTool}
                profile={profile}
                permissions={permissions}
                action={'membership'}
                target={membershipTarget}
                place='bottom'
              />
              <OverlayTrigger
                overlay={
                  <Tooltip id='tooltip-bottom'>Edit your membership</Tooltip>
                }
              >
                <Button
                  variant='outline-primary'
                  onClick={() => openMemberModal(true)}
                  disabled={
                    profile && profile.subUser
                      ? !getPermission(permissions, 'membership')
                      : false
                  }
                >
                  {profile.membership && profile.membership.metadata
                    ? profile.membership.metadata.nickname
                    : profile.membership
                      ? profile.membership.name
                      : ''}
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
          <Tabs onSelect={(e) => setActiveTab(e)} activeKey={activeTab}>
            <Tab
              eventKey={'edit-profile'}
              title={'Profile'}
              tabClassName={getClassName(activeTab)}
            >
              <Row className='page-body flex-md-row'>
                <Col className='ml-4 mr-5'>
                  <ProfileTable
                    profile={profile}
                    loading={loader}
                    onSubmit={async (obj) => {
                      setLoader(true);
                      const ob = await update(obj);
                      if (ob) {
                        setLoader(false);
                        toast.success('User data was updated successfully!', {
                          className: 'bg-primary text-white',
                          onClose: () => finishEdit(),
                        });
                      } else {
                        toast.error('An error occurred editing user data!');
                      }
                    }}
                    permissions={permissions}
                  />
                </Col>
                <Col md={5} className='space-around'>
                  {profile && profile.subUser ? null : (
                    <Row>
                      <Col className='settings'>
                        <div className='settings-title'>Settings</div>
                        <div className='sett-desc'>
                          You can invite users to your business account and
                          assign them specific permissions to perform!
                        </div>
                        <Button
                          onClick={() => {
                            openInviteModal(true);
                          }}
                        >
                          Invite Users
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {profile && profile.admin && !profile.subUser && (
                    <Row>
                      <Col className='settings'>
                        <div className='users-title text-center'>
                          Your account sub users
                        </div>
                        <div>
                          <Table>
                            <thead>
                              <tr
                                style={{
                                  fontSize: '16px',
                                  color: '#7c6363',
                                  fontWeight: 'bold',
                                }}
                              >
                                <td />
                                <td>Email</td>
                                <td>Name</td>
                                <td>Permissions</td>
                              </tr>
                            </thead>
                            <tbody>
                              {profile &&
                                profile.erpSubUsers &&
                                profile.erpSubUsers.map((usr, idx) => (
                                  <tr key={idx}>
                                    <td>
                                      <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                          <Tooltip id='tooltip-bottom'>
                                            Update user's permissions
                                          </Tooltip>
                                        }
                                      >
                                        <i
                                          className='fas fa-edit editSub'
                                          onClick={() => {
                                            setAuthUser(usr);
                                            setOpenEdit(true);
                                          }}
                                        />
                                      </OverlayTrigger>
                                    </td>
                                    <td>{usr.email}</td>
                                    <td>{usr.name}</td>
                                    <td>
                                      {usr.permissions &&
                                        usr.permissions.map((it) => (
                                          <li>{it}</li>
                                        ))}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      {!profile.cardInfo && (
                        <div className='card-alert text-center'>
                          Please enter your card information!
                        </div>
                      )}
                      <CreditCardInfo
                        isDefault={true}
                        profile={profile}
                        {...profile.cardInfo}
                        size={25}
                      />
                      <div
                        className='text-center'
                        onMouseEnter={() => {
                          if (profile && profile.subUser) {
                            if (
                              !getPermission(permissions, 'payment details')
                            ) {
                              setShowTool(true);
                            }
                          }
                        }}
                        onMouseLeave={() => {
                          if (profile && profile.subUser) {
                            setShowTool(false);
                          }
                        }}
                      >
                        <CustomTooltip
                          show={showTool}
                          profile={profile}
                          permissions={permissions}
                          action={'payment details'}
                          target={target}
                          place='bottom'
                        />
                        <Button
                          variant='link'
                          className='card-button'
                          onClick={() => openCardModal(true)}
                          disabled={
                            profile && profile.subUser
                              ? !getPermission(permissions, 'payment details')
                              : false
                          }
                          ref={target}
                        >
                          {profile.cardInfo
                            ? ' Change Credit card information'
                            : 'Enter Credit Card info'}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row />
                  <Row />
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey={'transactions'}
              title={'Transactions'}
              tabClassName={getClassName(activeTab)}
            >
              <Row className='page-body p-5 d-flex flex-column justify-content-start'>
                <div className='settings'>
                  <div className='login-stripe'>
                    <div className='pay-title'>Stripe connect dashboard*</div>
                    <div className='account-item'>
                      <div className='payout-desc'>
                        Get access to your account's data to review/edit them
                        through
                        <span style={{ color: '#6772e5', fontWeight: '600' }}>
                          {` Stripe Connect Express Dashboard:`}
                        </span>
                      </div>
                      <div className='act-link'>
                        <Button
                          className='btn-stripe-2'
                          onClick={() => {
                            window.open(stripeConnectLoginLink, '_self');
                          }}
                        >
                          {stripeComplete
                            ? 'Log In to your account!'
                            : 'Continue to Stripe Connect'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='transactions'>
                  {width <= 770 ? (
                    <>
                      {transactions &&
                        transactions.map((item, idx) => {
                          const dateString = localTimeUS(item.createdAt);
                          return (
                            <div className='responsive-table-mobile' key={idx}>
                              <div className='first-col'>
                                <div className='col'>#</div>
                                <div className='col'>Amount</div>
                                <div className='col'>Source</div>
                                <div className='col'>Order</div>
                                <div className='col'>User</div>
                                <div className='col'>Date</div>
                              </div>
                              <div className='second-col'>
                                <div className='col-content'>{`#${idx + 1
                                  }`}</div>
                                <div className='col-content'>{`$${(40 * Number(item.amount)) / 100
                                  }`}</div>
                                <div className='col-content'>{item.source}</div>
                                <div className='col-content'>
                                  {`#${item.orderId}`}
                                </div>
                                <div className='col-content'>
                                  {item.user.first_name +
                                    ' ' +
                                    item.user.last_name}
                                </div>
                                <div className='col-content'>{dateString}</div>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      <ul className='responsive-table'>
                        <li className='table-header'>
                          <div className='col col-1'>#</div>
                          <div className='col col-2'>Amount</div>
                          <div className='col col-3'>Source</div>
                          <div className='col col-4'>Order</div>
                          <div className='col col-5'>User</div>
                          <div className='col col-6'>Date</div>
                        </li>
                        {transactions &&
                          transactions.map((item, idx) => {
                            const dateString = localTimeUS(item.createdAt);
                            return (
                              <li className='table-row'>
                                <div className='col col-1'>{`#${idx + 1}`}</div>
                                <div className='col col-2'>{`$${Number(item.amount) -
                                  (40 * Number(item.amount)) / 100
                                  }`}</div>
                                <div className='col col-3'>{item.source}</div>
                                <div className='col col-4'>
                                  {`#${item.orderId}`}
                                </div>
                                <div className='col col-5'>
                                  {item.user.first_name +
                                    ' ' +
                                    item.user.last_name}
                                </div>
                                <div className='col col-6'>{dateString}</div>
                              </li>
                            );
                          })}
                      </ul>
                    </>
                  )}
                </div>
              </Row>
            </Tab>
          </Tabs>
        </Layout>
      </PrivateRoute>
      <MembershipSelect
        showCard={!profile.cardInfo ? true : false}
        show={isMemberModalOpen}
        handleClose={() => openMemberModal(false)}
        loading={login.userLoading}
        plan={(chosenMembership && chosenMembership) || {}}
        editPlan={chosenMembership ? chosenMembership : null}
        editProfile={true}
        selectedPlan={selectedPlan}
        set_SelectedPlan={_setSelectedPlan}
        erpMembershipContent={erpMembershipContent && erpMembershipContent}
        submitMembership={async (_, membership) => {
          const res = await changeMembership(
            profile.subUser ? profile.admin_id : profile.id,
            membership
          );
          if (res.membership) {
            _setSelectedPlan({});
            toast.success('Membership edited successfully!', {
              className: 'bg-primary text-white',
              onClose: () => finishEdit(),
            });
            setChosen(res.membership);
          } else {
            toast.error('An error occurred editing membership!', {
              onClose: () => finishEdit(),
            });
          }
          openMemberModal(false);
        }}
        setChosen={setChosen}
        cardInfo={profile ? profile.cardInfo : null}
      />
      <Modal
        title='Enter Your Credit Card Information'
        show={isCardModalOpen}
        handleClose={() => openCardModal(false)}
        primaryButtons={[
          {
            title: cardInfoLoad ? (
              <i className='fas fa-circle-notch fa-spin' />
            ) : (
              'Save new card'
            ),
            handle: collectCardInfo,
          },
        ]}
        dangerButtons={[
          { title: 'Cancel', handle: () => openCardModal(false) },
        ]}
      >
        <CreditCardModal setStripe={setStripe} />
        {cardErrors && <p>{cardErrors.message}</p>}
      </Modal>
      <Modal
        title='Invite users to access business account'
        show={inviteModal}
        handleClose={() => openInviteModal(false)}
        size={'md'}
      >
        <InviteUser
          handleClose={() => openInviteModal(false)}
          token={token}
          profile={profile}
          auth0Token={auth0Token}
          inviteModal={inviteModal}
        />
      </Modal>

      <Modal
        title='Edit user permissions'
        show={openEdit}
        handleClose={() => setOpenEdit(false)}
        size={'md'}
      >
        <EditUserAuth0
          user={authUser}
          handleClose={() => setOpenEdit(false)}
          auth0Token={auth0Token}
          token={token}
          profile={profile}
          inviteModal={openEdit}
          company={profile.fax}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.login.auth && state.login.auth.profile,
  login: state.login && state.login,
  userErpId: state.login.auth && state.login.auth.profile.erp_id,
  roadmap: state.create.answer && state.create.answer.roadmap,
  answers: state.create.answer && state.create.answer.answers,
  token: state.login.auth && state.login.auth.auth.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
  update: (user) => dispatch(updateUser(user)),
  changeMembership: (userID, membership) =>
    dispatch(changeMembership(userID, membership)),
  updateBilling: (userID, token) => dispatch(updateBilling(userID, token)),
  finishEdit: () => dispatch(finishEdit()),
  setRoadmapsToState: (data) => dispatch(setCustomerRoadmap(data)),
  setUserQuestions: (data) => dispatch(setUserChecklist(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
