import React, { useRef, useState } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { Link } from 'gatsby';

import CountryDropdown from './CountryDropdown';
import StateDropdown from './StateDropdown';
import { getPermission } from '../utils/helpers';
import CustomTooltip from './Tooltip';

const ProfileTable = (props) => {
  if (!props.profile) {
    return null;
  }

  function checkShopUrl(shopUrl) {
    if (shopUrl.includes('myshopify')) {
      return true;
    } else {
      return false;
    }
  }

  const btnRef = useRef(null);
  const [proShow, setProfShow] = useState(false);

  return (
    <>
      {props.profile && !props.profile.answers ? (
        <div className='in-profile-banner'>
          <p>Looks like you haven't completed your profile.</p>{' '}
          <Link className='in-profile-banner-title' to='/onboarding'>
            {' '}
            Click here to finish!
          </Link>
        </div>
      ) : null}
      <CustomTooltip
        show={proShow}
        profile={props.profile}
        permissions={props.permissions}
        action={'profile'}
        target={btnRef}
        place='bottom'
      />
      <Formik
        initialValues={props.profile}
        onSubmit={props.onSubmit}
        render={({ errors, touched, ...props }) => (
          <Form onSubmit={props.handleSubmit} validated={props.isValid}>
            {props.values.subUser && (
              <div style={{ color: '#7c6262' }}>
                <h4 className='text-center' style={{ fontWeight: 'bold' }}>
                  Company Profile
                </h4>
                <div className='mt-1 mb-1 text-center'>
                  Do not edit the company profile details unless you intend to
                  edit the Company profile. Please confirm with your
                  administrator before making any changes.
                </div>
              </div>
            )}
            <Table>
              <tbody>
                <tr>
                  <td>Bio</td>
                  <td>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label srOnly>Bio</Form.Label>
                      <Form.Control
                        as='textarea'
                        placeholder='Tell us about yourself!'
                        value={props.values.bio}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name='bio'
                        isInvalid={!!errors.bio}
                        isValid={!!!errors.bio && touched.bio}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.bio}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label srOnly>Email</Form.Label>
                      <Form.Control
                        type='email'
                        placeholder=''
                        value={props.values.email}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name='email'
                        isInvalid={!!errors.email}
                        isValid={!!!errors.email && touched.email}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>First Name</td>
                  <td>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label srOnly>First Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={props.values.firstName}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name='firstName'
                        isInvalid={!!errors.firstName}
                        isValid={!!!errors.firstName && touched.firstName}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label srOnly>Last Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={props.values.lastName}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name='lastName'
                        isInvalid={!!errors.lastName}
                        isValid={!!!errors.lastName && touched.lastName}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label srOnly>Phone Number</Form.Label>
                      <Form.Control
                        type='tel'
                        value={props.values.phone}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name='phone'
                        isInvalid={!!errors.phone}
                        isValid={!!!errors.phone && touched.phone}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>
                    <CountryDropdown
                      value={props.values.address.country}
                      handleBlur={props.handleBlur}
                      onChange={props.handleChange}
                      srOnly
                      name='address.country'
                      isInvalid={!!(errors.address && errors.address.country)}
                      isValid={
                        !!!(errors.address && errors.address.country) &&
                        touched.address &&
                        touched.address.country
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Street Address</td>
                  <td>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label srOnly>Street Address</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder=''
                        value={props.values.address.address1}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name='address.address1'
                        isInvalid={
                          !!(errors.address && errors.address.address1)
                        }
                        isValid={
                          !!!(errors.address && errors.address.address1) &&
                          touched.address &&
                          touched.address.address1
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.address && errors.address.address1}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>Suite or Apartment Number</td>
                  <td>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label srOnly>Suite or Apartment Number</Form.Label>
                      <Form.Control
                        type='text'
                        value={props.values.address.address2}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name='address.address2'
                        isInvalid={
                          !!(errors.address && errors.address.address2)
                        }
                        isValid={
                          !!!(errors.address && errors.address.address2) &&
                          touched.address &&
                          touched.address.address2
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.address && errors.address.address2}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label srOnly>City</Form.Label>
                      <Form.Control
                        type='text'
                        value={props.values.address.city}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name='address.city'
                        isInvalid={!!(errors.address && errors.address.city)}
                        isValid={
                          !!!(errors.address && errors.address.city) &&
                          touched.address &&
                          touched.address.city
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.address && errors.address.city}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>State</td>
                  <td>
                    <StateDropdown
                      id={props.values.address.country}
                      value={props.values.address.state}
                      handleBlur={props.handleBlur}
                      onChange={props.handleChange}
                      srOnly
                      name='address.state'
                      isInvalid={!!(errors.address && errors.address.state)}
                      isValid={
                        !!!(errors.address && errors.address.state) &&
                        touched.address &&
                        touched.address.state
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Zipcode</td>
                  <td>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label srOnly>Zipcode</Form.Label>
                      <Form.Control
                        type='text'
                        value={props.values.address.zipcode}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name='address.zipcode'
                        isInvalid={!!(errors.address && errors.address.zipcode)}
                        isValid={
                          !!!(errors.address && errors.address.zipcode) &&
                          touched.address &&
                          touched.address.zipcode
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.address && errors.address.zipcode}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>Shopify Store URL</td>
                  <td>
                    <Form.Group controlId='formBasicShopifyURL'>
                      <Form.Label srOnly>Shopify Store URL</Form.Label>
                      <Form.Control
                        name='shopifyUrl'
                        type='text'
                        placeholder=''
                        value={
                          props.values.shopifyUrl ? props.values.shopifyUrl : ''
                        }
                        onBlur={props.handleBlur}
                        onChange={(e) => {
                          props.handleChange(e);
                        }}
                        isInvalid={!!errors.shopifyUrl}
                        isValid={!!!errors.shopifyUrl && touched.shopifyUrl}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.shopifyUrl}
                      </Form.Control.Feedback>
                      {props.values.shopifyUrl ? (
                        checkShopUrl(props.values.shopifyUrl) ? null : (
                          <div className='text-center text-danger' style={{}}>
                            Your Store URL must contain myshopify.com for it to
                            be valid. (i.e. www.yoursite.myshopify.com) - please
                            go to
                            <a
                              href={'https://support.gildform.com'}
                              target='_blank'
                              style={{ margin: '5px' }}
                            >
                              support.gildform.com
                            </a>
                            for more information.
                          </div>
                        )
                      ) : null}
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>Shopify Access Token</td>
                  <td>
                    <Form.Group controlId='formBasicShopifyAccessToken'>
                      <Form.Label srOnly>Shopify Access Token</Form.Label>
                      <Form.Control
                        name='shopifyToken'
                        type='text'
                        placeholder=''
                        value={
                          props.values.shopifyToken
                            ? props.values.shopifyToken
                            : ''
                        }
                        onBlur={props.handleBlur}
                        onChange={(e) => {
                          props.handleChange(e);
                        }}
                        isInvalid={!!errors.shopifyToken}
                        isValid={!!!errors.shopifyToken && touched.shopifyToken}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.shopifyToken}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div
              onMouseEnter={() => {
                if (props.values.subUser) {
                  if (!getPermission(props.permissions, 'profile')) {
                    setProfShow(true);
                  }
                }
              }}
              onMouseLeave={() => {
                setProfShow(false);
              }}
            >
              <Button
                className='save-padding'
                type='submit'
                disabled={
                  props.loading || props.values.subUser
                    ? !getPermission(props.permissions, 'profile')
                    : false
                }
                ref={btnRef}
              >
                {props.loading ? (
                  <i className='fas fa-circle-notch fa-spin' />
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          </Form>
        )}
      />
    </>
  );
};

export default ProfileTable;
